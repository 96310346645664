// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Variables
@import 'media';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.text-required {
    color: red;
    font-size: .8rem;
}
.card-list__detail .inner {
    padding: 1rem;
    margin: 0 0 1rem;
    border: solid 1px #bbb;
}

.wrap-container.is_admin {
    .navbar {
        background: #1d5987;
    }
    .navbar-brand,
    .nav-link {
        color: #fff;
    }
    .dropdown-item {
        color: #222;
    }
}


.dl-list-wrap {
    border-top: dotted 1px #bbb;
    padding: 0;
    margin: 0;
    //&:nth-child(2n) {
    //    background: #efefef;
    //}
}
.dl-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    margin: 0;
    &:nth-child(odd) {
        background: #efefef;
    }
    &.dl_title {
        background: #bbb;
    }
}
.dl-list__title {
    flex-basis: 30%;
    //flex-grow: 2;
    padding: 0;
    margin: 0;
    @include media_desktop() {
        white-space: nowrap;
    }
}

.dl-list__data {
    flex-basis: 10%;
    max-width: 20%;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    @include media_desktop() {
        //white-space: nowrap;
    }
}

