@import "./variables";


@mixin media_desktop(){
    @media only screen and (min-width: $desktop) {
        @content;
    }
}

@mixin media_desktop2(){
    @media only screen and (min-width: $desktop2) {
        @content;
    }
}
